import React, { useState, useEffect } from 'react';
import { Card, GridWrapper, GridItem } from "@jsluna/react";
import FormAlerts from '../Common/FormAlerts';
import { links } from '../../types/Entities/Links';
import ApplicationLinksApi from '../../api/applicationLinksApi';
import { useApiClient } from '../../context/AppContext'


const Links = () => {
    const [items, setItems] = useState<links[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [errorAlert, setErrorAlert] = useState<false | string>(false);
  const [successAlert, setSuccessAlert] = useState<false | string>(false);

  const apiClient = useApiClient();

    useEffect(() => {
        getallApplicationLinks();
    },[]);

    const getallApplicationLinks = async () => {
        return ApplicationLinksApi.getallApplicationLinks(apiClient)
        .then((response) => {
            setItems(response)
            })
          .catch(error => setErrorAlert('Something went wrong!'))
      }

    const clearAlerts = () => {
        setErrorAlert(false);
        setSuccessAlert(false);
      };
    

    const renderBoxes = () => {
        return items.map((item, index) => (
            <GridItem key={index} size={{ sm: '1/3' }} style={{ marginTop:"10px", textAlign:'center' , alignItems:"center"}}>
                <a href={item.url} target="_blank" style={{textDecoration:'none'}}>
                    
                    <Card
                        className="cb-c-alert-list-item__drop-shadow cb-c-alert-list-item"
                        style={{ height: '150px', backgroundColor: 'orange', color: 'white',display:'flex',justifyContent:'center',alignItems:'center'}}
                    >
                        <div className="ln-u-display-3-fixed cb-c-alert-list-item__value cb-u-text-align-center-responsive" >
                            {item.displayName}
                        </div>
                    </Card>
                </a>
            </GridItem>
            
        ));
    }

    return (
        <>
        <FormAlerts
        showError={errorAlert}
        showSuccess={successAlert}
        onClose={clearAlerts}
      />
        <GridWrapper className="ln-o-grid--center">
            {renderBoxes()}
        </GridWrapper>
        </>
    );
};

export default Links;