import { Modal as ReactModal, ModalHeading } from '@jsluna/react'
import {
  FilledButton,
  OutlinedButton,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
} from '@jsluna/button'

interface Props {
  id?:string;
  isOpen: boolean;
  heading: string;
  body: string |JSX.Element;
  primaryButtonTitle: string;
  secondaryButtonTitle: string;
  handlePrimaryButtonClick: (event: Event) => void;
  handleSecondaryButtonClick: (event: Event) => void;
  primaryButtonDisabled ?: boolean,
  children?: React.ReactNode;
}

const Modal = (props: Props) => {
  return (
    <ReactModal restrictClose alert open={props.isOpen} className='modal'>
      <h3  id={"modal-1"}>{props.heading}</h3>
      <p style={{minWidth: '40vw'}}>{props.body}</p>
      {props.children}
      <ButtonGroupWrapper>
        <ButtonGroupPrimary>
          <FilledButton onClick={props.handlePrimaryButtonClick} disabled={props?.primaryButtonDisabled}>{props.primaryButtonTitle}</FilledButton>
        </ButtonGroupPrimary>
        <ButtonGroupSecondary>
          <OutlinedButton onClick={props.handleSecondaryButtonClick}>{props.secondaryButtonTitle}</OutlinedButton>
        </ButtonGroupSecondary>
      </ButtonGroupWrapper>
    </ReactModal>
  )
}

export default Modal