import { Search } from '@jsluna/icons'
import SitesApi from '../../api/sitesApi'
import FormAlerts from "../Common/FormAlerts";
import { Pagination } from '@jsluna/pagination';
import { AutocompleteField } from "@jsluna/react";
import WorkordersList from '../Common/WorkordersList'
import { useApiClient } from '../../context/AppContext'
import React, { useRef, useState, useEffect } from 'react'
import riskWorkordersApi from '../../api/riskWorkordersApi'
import { FilledButton, OutlinedButton, TextInputField, TextInput } from '@jsluna/react'
import { Workorders, RiskWorkorderFilter, RiskWorkorderModel,  } from '../../types/Entities/Workorders'
import { GridWrapper, GridItem, SearchInput, CheckboxField, FormGroup,  SelectField } from '@jsluna/react'
import { debug } from 'util';


const RiskWorkorders = (props: { status : Boolean }) => {
  const recordsPerPage = 20;
  const apiClient = useApiClient()
  const [sortBy , setSortBy] =useState('')
  const [loader, setLoader] = useState(false)
  const [siteOptions, setSiteOptions] = useState([{}])
  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalRecords, setTotalRecords] = useState<number>()
  const [errorAlert, setErrorAlert] = useState<false | string>(false)
  const [successAlert, setSuccessAlert] = useState<false | string>(false)
  const [riskWorkorders, setRiskWorkorders] = useState([] as Workorders[]);
  const [workorderStatus, setWorkorderStatus] = useState([] as Array<Array<string | Boolean>>)
  const defaultFalseStatus = [["Open", false], ["In Progress", false], ["Closed", false]]
  const defaultTrueStatus = [["Open", true], ["In Progress", true], ["Closed", false]]
  const sortByOptions = [
    { value: 'Risk Score Ascending', label: 'Risk Score Ascending' },
    { value: 'Risk Score Descending', label: 'Risk Score Descending' },
    { value: 'dateAscending', label: ' Latest modified date' },
    { value: 'dateDescending', label: 'Oldest modified date' },
  ]
  
  const handleScrollModal = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    })
  }
  
  const defaultFilter ={
    SearchByWorkOrderNumber: "",
    SearchBySiteName: "",
    SearchByStatus: ["Open", "In Progress"],
    SortColumn: "risk_score",
    SortColumnDirection: "desc",
    StartIndex: 1,
    PageSize: recordsPerPage
  }
  const [filter, setFilter] = useState<RiskWorkorderFilter>(defaultFilter)
  const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)

  const getFilterFromLocalStorage = (filterData: string) => {
    return filterData ? JSON.parse(filterData) : null;
};

useEffect(() => {
  fetchData();
  const interval = setInterval(() => {
    fetchDataFor_N_Seconds()
  }, 200000);
  return () => clearInterval(interval);
  
  }, []);

  const fetchDataFor_N_Seconds = () => {
      const filterData = localStorage.getItem('riskWorkorderFilter') as string;
      const filterdDataObject=getFilterFromLocalStorage(filterData)
        if( filterdDataObject){
          setCurrentPage(filterdDataObject.StartIndex)
          for(var i=0 ; i<filterdDataObject.SearchByStatus.length;i++){
            const index = defaultFalseStatus.findIndex(item => item[0] === filterdDataObject.SearchByStatus[i]);
              defaultFalseStatus[index][1]=true
          }
          setWorkorderStatus(defaultFalseStatus)
          if(filterdDataObject.SortColumn === 'risk_score'){
            setSortBy(filterdDataObject.SortColumnDirection === 'asc' ? 'Risk Score Ascending' : 'Risk Score Descending')
          }else{
            setSortBy(filterdDataObject.SortColumnDirection === 'desc' ? 'dateAscending' : 'dateDescending')
          }
          setFilter(filterdDataObject)
          loadallriskworkorderspaging(filterdDataObject)
        }else{
          setWorkorderStatus(defaultTrueStatus)
          setFilter(defaultFilter)
          loadallriskworkorderspaging(defaultFilter)
          localStorage.setItem('riskWorkorderFilter' , JSON.stringify(defaultFilter))
        }
  }

  const fetchData = async () => {
    const filterData = localStorage.getItem('riskWorkorderFilter') as string;
    const filterdDataObject=getFilterFromLocalStorage(filterData)
    if(!props.status && filterdDataObject){
        setCurrentPage(filterdDataObject.StartIndex)
        for(var i=0 ; i<filterdDataObject.SearchByStatus.length;i++){
          const index = defaultFalseStatus.findIndex(item => item[0] === filterdDataObject.SearchByStatus[i]);
            defaultFalseStatus[index][1]=true
        }
        setWorkorderStatus(defaultFalseStatus)
        if(filterdDataObject.SortColumn === 'risk_score'){
          if(filterdDataObject.SortColumnDirection === 'asc'){
            setSortBy('Risk Score Ascending')
          }else{
            setSortBy('Risk Score Descending')
          }
        }else{
          if(filterdDataObject.SortColumnDirection === 'desc'){
            setSortBy('dateAscending')
          }else{
            setSortBy('dateDescending')
          }
        }
        setFilter(filterdDataObject)
        await loadriskwo(filterdDataObject);
      }else{
        setWorkorderStatus(defaultTrueStatus)
        setFilter(defaultFilter)
        localStorage.setItem('riskWorkorderFilter' , JSON.stringify(defaultFilter))
        await loadriskwo(defaultFilter)
      }
    await getsitesApi();
  }

  const getsitesApi = async () => {
    return SitesApi.getallSiteNames(apiClient)
    .then((response) => {
      setSiteOptions(response)
    })
      .catch(error => setErrorAlert('Something went wrong!'))
  }
  
  const loadriskwo = async (filters: RiskWorkorderFilter) => {
    setLoader(true)
    await loadallriskworkorderspaging(filters)
    setLoader(false)
    scrollToTop()
  }
  
  const serachFilter= async () => {
    const filterforSearch={...filter}
    filterforSearch.StartIndex=1
    setFilter(filterforSearch)
    setCurrentPage(1)
    loadriskwo(filterforSearch)
    localStorage.setItem('riskWorkorderFilter' , JSON.stringify(filterforSearch))
  }

  const clearAlerts = () => {
    setErrorAlert(false);
    // setSuccessAlert(false);
  }

  const loadallriskworkorderspaging = async (filters: RiskWorkorderFilter): Promise<RiskWorkorderModel> => {
    return riskWorkordersApi
      .getallriskworkorderspaging(apiClient, filters)
      .then((response) => {
        setRiskWorkorders(response.riskWorkOrders)
        setTotalRecords(response.recordsCount)
        if (!response) {
          throw new Error('Invalid response.')
        }
      }).catch(error => {
        setErrorAlert('Something went wrong!')
        return error
      })
  } 

  const SearchRiskworkOrders = (workorderNumber: any) => {
    const regex = /^[0-9]+$/;
    const newValue = workorderNumber;
    if(regex.test(newValue) || newValue===""){
    setFilter((prevFilter: RiskWorkorderFilter) => ({
      ...prevFilter,
      SearchByWorkOrderNumber: newValue,
    }))
  }
  }

  const CheckCheckBox = (props: any) => {
    let searchByStatusValue = filter.SearchByStatus;
    const index = workorderStatus.findIndex(item => item[0] === props.target.defaultValue);
    
    if (props.target.checked) {
      if (!searchByStatusValue?.includes(props.target.defaultValue)) {
        searchByStatusValue?.push(props.target.defaultValue);
        let workOrderStatusFilter = [...workorderStatus]
        workOrderStatusFilter[index]=[props.target.defaultValue , 'true']
        setWorkorderStatus(workOrderStatusFilter)
      }
    } else {
      searchByStatusValue = searchByStatusValue?.filter((status: any) => status !== props.target.defaultValue);
      let workOrderStatusFilter = [...workorderStatus]
      workOrderStatusFilter[index]=[props.target.defaultValue , 'false']
      setWorkorderStatus(workOrderStatusFilter)
    }
    setFilter((prevFilter: RiskWorkorderFilter) => ({
      ...prevFilter,
      SearchByStatus: searchByStatusValue
    }));
  }

  const selectedSite = (props: any) => {
    if(props === null){
      setFilter((prevFilter: RiskWorkorderFilter) => ({
        ...prevFilter,
        SearchBySiteName: ''
      }));
    }else{
      if(props && props.value){
        setFilter((prevFilter: RiskWorkorderFilter) => ({
          ...prevFilter,
          SearchBySiteName: props.value
        }));
      }else{
        setFilter((prevFilter: RiskWorkorderFilter) => ({
          ...prevFilter,
          SearchBySiteName: props.target.value
        }));
      }
    }
  }

  const selectSortBy = (props: any) => {
    switch (props.target.value) {
      case 'Risk Score Ascending':
        setFilter((prevFilter: RiskWorkorderFilter) => ({
          ...prevFilter,
          SortColumn: 'risk_score',
          SortColumnDirection: 'asc'
        }));
        setSortBy('Risk Score Ascending')
        break;
      case 'Risk Score Descending':
        setFilter((prevFilter: RiskWorkorderFilter) => ({
          ...prevFilter,
          SortColumn: 'risk_score',
          SortColumnDirection: 'desc'
        }));
        setSortBy( 'Risk Score Descending')
        break;
      case "dateAscending":
        setFilter((prevFilter: RiskWorkorderFilter) => ({
          ...prevFilter,
          SortColumn: 'datemodified',
          SortColumnDirection: 'desc'
        }));
        setSortBy('dateAscending')
        break;
      case "dateDescending":
        setFilter((prevFilter: RiskWorkorderFilter) => ({
          ...prevFilter,
          SortColumn: 'datemodified',
          SortColumnDirection: 'asc'
        }));
        setSortBy('dateDescending')
        break;
      default :
        setSortBy('')
        break;
    }
  }

  const discardFilters = async () => {
    setWorkorderStatus(defaultTrueStatus)
    setCurrentPage(1)
    setFilter(defaultFilter)
    localStorage.setItem('riskWorkorderFilter' , JSON.stringify(defaultFilter))
    window.location.reload()
    scrollToTop()
  }

  const isMobile = () => {
    if (window.innerWidth > 1200) {
      return false;
    }
    return true;
  };
  return (
    <>
      <FormAlerts
        showError={errorAlert}
        showSuccess={successAlert}
        onClose={clearAlerts}
      />
      <GridWrapper>
        <GridItem size={{ xs: "1/1", lg: "1/4" }} >
          <div style={{display:'contents'}}
            className={ "cb-c-alerts-page__filter-container--full-screen"}>
            <GridItem
              size={{ xs: "1/1", sm: "1/1" }}
              className="cb-c-alerts-page__left-panel alerts-page__filter">
              <div className="cb-c-alerts-page__options">
                <div>
                  <h4>Find Workorder</h4>
                  <FormGroup name="workordernumber" label="Workorder Number">
                    <SearchInput
                      name="workorder-search"
                      value={filter.SearchByWorkOrderNumber}
                      placeholder="e.g. 56870001"
                      onChange={(e: any) => SearchRiskworkOrders(e.target.value)}
                      hasAction
                      action={
                        <button onClick={() => serachFilter()}>
                          <Search />
                        </button>
                      }
                    /></FormGroup>
                  <div>
                    <GridWrapper>
                      <GridItem size="1/1">
                        <h3>Filter Workorders</h3>
                      </GridItem>
                    </GridWrapper>
                  </div>
                  <FormGroup name="workordernumber" label="Workorder Status">
                    <GridWrapper name='Workorder' label='workorder'>
                      <GridItem size='4/5'>
                        {" "}
                        {workorderStatus && 
                        workorderStatus.map((status , index) => (
                          <ul key={index}>
                            <CheckboxField
                              name={status[0]}
                              checked={status[1]}
                              label="Field label"
                              hideLabel
                              placeholder="Placeholder"
                              onChange={CheckCheckBox}
                              options={[
                                {
                                  value: status[0],
                                  label: status[0],
                                  defaultChecked: status[1]
                                }
                              ]}
                            />
                          </ul>
                        ))}
                      </GridItem>
                      <GridItem>
                        <AutocompleteField
                          value={filter.SearchBySiteName}
                          label={'Site'}
                          defaultSelectedOption={filter.SearchBySiteName}
                          placeholder="Please select a site"
                          name={"SiteSelector"}
                          onSelect={(selectedSiteOption: any) => selectedSite(selectedSiteOption)}
                          onChange={ selectedSite}
                          options={siteOptions}
                          noResultsMessage="No stores found"
                          /> 
                        <SelectField
                          value={sortBy}
                          name={"StoreSelector"}
                          label="Sort By"
                          options={sortByOptions}
                          // defaultSelectedOption={"select"}
                          // placeholder={'select sort by'}
                          onSelect={selectSortBy}
                          onChange={selectSortBy}
                          noResultsMessage="No stores found"
                        />
                      </GridItem>
                    </GridWrapper>
                  </FormGroup>
                  <>
                    <GridItem size={{ xl: '1', ss: '1', default: '1/1' }} className='formInputMaxWidth'>
                      <div className='formButtonGroup'>
                        <OutlinedButton size='200px' onScroll={handleScrollModal} onClick={discardFilters} className='formDiscardButton' >Discard</OutlinedButton>
                        <FilledButton size='200px' onScroll={handleScrollModal} onClick={serachFilter} className='formSaveButton' >Search</FilledButton>
                      </div>
                    </GridItem>
                  </>

                </div>
              </div>
            </GridItem>
            <br/><br/>
          </div>
        </GridItem> 
        <GridItem style={{overflow: 'hidden', overflowY: 'scroll'}}
          className={"cb-c-alerts-page__right-panel"}
          size={{ xs: "1/1", lg: "3/4" }}>
          {!loader && (
            riskWorkorders.length > 0 ? riskWorkorders.map((workorder, index) => (
              <div key={workorder.id}>
                <WorkordersList workorder={workorder} />
              </div>
            )) : <div>There is no data to display</div>)}
          {
            (loader === true) && (<div className='loader'></div>)
          }
          {riskWorkorders.length > 0 && !loader &&
            <Pagination
              showFirstAndLast
              showPages
              current={currentPage}
              total={totalRecords ? Math.ceil(totalRecords / recordsPerPage) : 1}
              onChange={(page: number, e: React.MouseEvent<HTMLButtonElement>) => {
                setCurrentPage(page);
                const filterforPage={...filter}
                filterforPage.StartIndex=page
                localStorage.setItem('riskWorkorderFilter' , JSON.stringify(filterforPage))
                setFilter((prevFilter: RiskWorkorderFilter) => ({
                  ...prevFilter,
                  StartIndex: page
                }));
                loadriskwo(filterforPage)
              }}
            />
          }
        </GridItem>
      </GridWrapper>
    </>
  )
}

export default RiskWorkorders