import { useMsal } from '@azure/msal-react'
import useApi from '../../../api/ApiClient';
import usernameApi from '../../../api/riskWorkordersApi'
import { useApiClient } from '../../../context/AppContext';

const SignOutButton = () => {
    const ApiClient = useApiClient();
    const { instance } = useMsal()
    const username = usernameApi.getUsername(ApiClient);

    const handleSignOut = () => {
        instance.logoutRedirect()
    }

    return (
        <div className='ln-c-topbar__item ln-c-header__global-item'>
            {/* class="ln-c-header__global-item ln-c-header__global-item--right ln-o-bare-link" */}
            <span className=' ln-c-topbar__text ln-u-soft-right username logOut'>{username}</span>
            <a
                className='ln-o-bare-link ln-c-topbar__text logOut'
                href='#signout'
                id='logout'
                key='logout'
                aria-selected='true' role='tab'
                aria-controls='logout'
                onClick={handleSignOut}
            >Logout</a>
        </div>
    )
}

export default SignOutButton