import ApiClient from './ApiClient'
import { IncidentWorkorders, IncidentModel, IncidentsFilter, LogNote, RootCause } from '../types/Entities/IncidentManagement'


const incidentWorkorders = [
  {
    id: '9A4A73B2-C73F-4714-8C27-72E7D0C6CB37', workOrderNumber: '102997729', siteName: 'A0441', siteDescription:'POUND LANE', siteType:'Supermarket',
    region: 'JS Region 18', locationAtSite:'Sales Floor', incidentCategory:'Major Incident', incidentType:'Crime Issue - Break In/Buglary Related Issues', incidentDescription:`Customer Assurance has been informed the site has been ram raided. The automatic door has been damaged and the sprinkler system has also been damaged. Police are on site and forensics will be attending.`,
    dateCreated:'26 Jul 2024 14:37', dateModified:'26 Jul 2024 14:37', dateCompleted:'string', incidentTimer:'1.32', updatesRequired:30, woStatus: 'Open', clientName: "Sainsburys"
  },
  {
    id: '9A4A73B2-C73F-4714-8C27-72E7D0C6CB37', workOrderNumber: '100747733', siteName: 'A4230', siteDescription:'GLASGOW - KILLERMONT ST LOC', siteType:'Supermarket',
    region: 'JS Region 8', locationAtSite:'AMBIENT', incidentCategory:'Major Incident', incidentType:'Crime Issue - Break In/Buglary Related Issues', incidentDescription:'Site checks WO requested by: Contractor',
    dateCreated:'26 Jul 2024 14:37', dateModified:'26 Jul 2024 14:37', dateCompleted:'string', incidentTimer:'1.32', updatesRequired:5, woStatus: 'Assigned', clientName: "Sainsburys"
  },
  {
    id: '9A4A73B2-C73F-4714-8C27-72E7D0C6CB37', workOrderNumber: '102511235', siteName: 'A0656', siteDescription:'FERNDOWN', siteType:'Supermarket',
    region: 'JS Region 19', locationAtSite:'CAR PARK', incidentCategory:'Major Incident', incidentType:'Crime Issue - Break In/Buglary Related Issues', incidentDescription:`**this is to go to subcontractor**  Lines at the front of the store are disappearing and becoming dangerous . Is surface concrete or tarmac? Tarmac. If Potholes need filling, how many? White line need repainting as can no longer visible and dangerous . 2 image(s) attached. WO requested by: Contractor`,
    dateCreated:'26 Jul 2024 14:37', dateModified:'26 Jul 2024 14:37', dateCompleted:'string', incidentTimer:'1.32', updatesRequired:15, woStatus: 'Assigned', clientName: "Sainsburys"
  },
  {
    id: '9A4A73B2-C73F-4714-8C27-72E7D0C6CB37', workOrderNumber: '102752742', siteName: 'D0495', siteDescription:'DAVENTRY DEPOT', siteType:'Supermarket',
    region: 'JS Region 20', locationAtSite:'WAREHOUSE', incidentCategory:'Major Incident', incidentType:'Crime Issue - Break In/Buglary Related Issues', incidentDescription:`Damaged racking at AR019 Has the Depot ceased operations?: No`,
    dateCreated:'26 Jul 2024 14:37', dateModified:'26 Jul 2024 14:37', dateCompleted:'string', incidentTimer:'1.32', updatesRequired:15, woStatus: 'Assigned', clientName: "Sainsburys"
  },
  {
    id: '9A4A73B2-C73F-4714-8C27-72E7D0C6CB37', workOrderNumber: '102981245', siteName: 'A2073', siteDescription:'CRAMLINGTON', siteType:'Supermarket',
    region: 'JS Region 21', locationAtSite:'CUSTOMER ENTRANCE', incidentCategory:'Major Incident', incidentType:'Crime Issue - Break In/Buglary Related Issues', incidentDescription:`LWO BER Work Order No.102860962 WO requested by: Sainsbury's Colleague (Non Store Based)`,
    dateCreated:'26 Jul 2024 14:37', dateModified:'26 Jul 2024 14:37', dateCompleted:'string', incidentTimer:'1.32', updatesRequired:20, woStatus: 'Assigned', clientName: "Sainsburys"
  }
  
]

const linked_WO=[
  {
    id: "string", workOrderNumber: "102645910", assignedContractor: "Record (UK) Limited",
    category: "AUTO DOORS, AUTO SLIDING DOOR", subCategory: "AUTO DOORS, AUTO SLIDING DOOR", problemType: "Stuck Open - Unable to secure",woStatus: "ASSIGNED", eta: "7/26/2024 14:46", dateModified: "7/26/2024 15:03", latestComment: "Engineer Allocated WO",
  },
  {
    id: "string", workOrderNumber: "102645911", assignedContractor: "FEN-BAY SERVICES LTD",
    category: "SHUTTERS, OVERHEAD SHUTTER", subCategory: "SHUTTERS, OVERHEAD SHUTTER", problemType: "Trade Critical Shutter Issue", woStatus: "SERVICE_INCOMPLETE", eta: "7/26/2024 14:50", dateModified: "7/26/2024 15:02", latestComment: "Customer Assurance has been informed the site has been ram raided. The automatic door has been damaged and the sprinkler system has also been damaged. Police are on site and forensics will be attending. Customer Assurance has been informed the site has been ram raided. The automatic door has been damaged and the sprinkler system has also been damaged. Police are on site and forensics will be attending.",
  },
  {
    id: "string", workOrderNumber: "102645912", assignedContractor: "Arcus Facilities Services Limited",
    category: "CLEANING, EMERGENCY CLEANING", subCategory: "CLEANING, EMERGENCY CLEANING", problemType: "Cleaning Required", woStatus: "ASSIGNED", eta: "7/26/2024 14:51", dateModified: "7/26/2024 15:16", latestComment: "Awaiting other attendance at site",
  },
  {
    id: "string", workOrderNumber: "102645913", assignedContractor: "KB (Carters)",
    category: "ELECTRICAL / POWER SUPPLY, ELECTRICAL FAULT", subCategory: "ELECTRICAL / POWER SUPPLY, ELECTRICAL FAULT", problemType: "Total Power Fail - Whole Store", woStatus: "ASSIGNED", eta: "7/26/2024 15:06", dateModified: "7/26/2024 15:07", latestComment: "Engineer Allocated WO",
  },
  {
    id: "string", workOrderNumber: "102645914", assignedContractor: "Jim Hastings Ltd",
    category: "WORK REQUEST, STRUCTURAL SURVEYOR", subCategory: "WORK REQUEST, STRUCTURAL SURVEYOR", problemType: "Cracked Wall", woStatus: "ASSIGNED", eta: "7/26/2024 15:10", dateModified: "7/26/2024 15:10", latestComment: "Accepted",
  },
]

const incidentWorkorder =
  {
    id: '9A4A73B2-C73F-4714-8C27-72E7D0C6CB37', workOrderNumber: '102997729', siteName: 'A0441', siteDescription:'POUND LANE', siteType:'Supermarket', region: 'JS Region 18',
    locationAtSite:'Sales Floor', incidentCategory:'Major Incident', incidentType:'Crime Issue - Break In/Buglary Related Issues',
    incidentDescription:`Customer Assurance has been informed the site has been ram raided. The automatic door has been damaged and the sprinkler system has also been damaged. Police are on site and forensics will be attending.`,
    dateCreated:'26 Jul 24, 14:37', dateModified:'29 Jul 24, 14:37', dateCompleted:'28 Jul 24, 14:37', incidentTimer:'1.32', updatesRequired:30, woStatus: 'Assigned', clientName: "Sainsburys"
  }

  const comments =[{
    id:"11", workOrderNumber: "102997729",
    commentedBy:'pavan',
    commentedDate:new Date(),
    comments:'Hey hello'
  }]

const getallincidentspaging = async (apiClient: ApiClient, payload: IncidentsFilter): Promise<IncidentModel> => {
  const queryParams = new URLSearchParams(payload as any).toString();
  return apiClient
    .get<IncidentModel >(`IncidentManagement/getincidentworkordesbypaging?${queryParams}`)
    .catch(error => { throw error });
}

const getincidentsbywo = async (apiClient: ApiClient, workordernumber: string):
  Promise<IncidentWorkorders> => {
  return apiClient
    .get<IncidentWorkorders>(`IncidentManagement/getincidentworkordersbyworkordernumber?workordernumber=${workordernumber}`)
    .catch(error => { throw error })
}

const woLogNote= async(apiClient:ApiClient,payload:LogNote):
  Promise<Response> =>{
    return apiClient
    .post('IncidentManagement/wolognote',JSON.stringify(payload))
    .catch(error=>{throw error})
}

const rootCausePost= async(apiClient:ApiClient,payload:RootCause):
  Promise<Response> =>{
    return apiClient
    .post('IncidentManagement/womanagerreview',JSON.stringify(payload))
    .catch(error=>{throw error})
}

const incidentComplete= async(apiClient:ApiClient,workordernumber:string):
  Promise<Response> =>{
    return apiClient
    .get<Response>(`IncidentManagement/wocompletework?workordernumber=${workordernumber}`)
    .catch(error=>{throw error})
}

const IncidentManagementApi = {
  getallincidentspaging,
  getincidentsbywo,
  woLogNote,
  rootCausePost,
  incidentComplete
}


export default IncidentManagementApi