import ApiClient from './ApiClient'
import {SiteCareManagerContactModel} from '../types/Entities/SiteCareManagerType'


const getallregiondetails = async (apiClient: ApiClient) => {
  return apiClient
    .get<Array<SiteCareManagerContactModel>>(`SiteCareManager/getallregiondetails`)
    .catch(error => { throw error })
}


const SiteCareManagerApi = {
  getallregiondetails
}
export default SiteCareManagerApi