import ApiClient from './ApiClient'
import {DropDownValues,DDLBinding} from '../types/Entities/SitesType'


const getallSites = async (apiClient: ApiClient) => {
  return apiClient
    .get<Array<string>>(`Sites/getallsites`)
    .catch(error => { throw error })
}

const getallSiteNames = async (apiClient: ApiClient) => {
  return apiClient
    .get<Array<DDLBinding>>(`Sites/getallsitenames`)
    .catch(error => { throw error })
}


const SitesApi = {
    getallSites,
    getallSiteNames
}
export default SitesApi