import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { Sainsburys } from '@jsluna/images'
import {
  Header,
  HeaderGlobalBar,
  HeaderGlobalItem,
  HeaderLogo,
  HeaderMainBar,
  HeaderNav,
  ListGroup,
  ListGroupItem,  
  ListGroupDropdown,
} from '@jsluna/react'

import SignOutButton from '../SignOutButton'
import Constants from '../../../utils/constants'
import Feedback from '../../Feedback'

interface PageHeaderProps {
  roles: string[]
}

const PageHeader = ({ roles }: PageHeaderProps) => {
  const [width, setWidth] = useState(window.screen.width)
  const path = useLocation().pathname
  const feedback: any = useRef()

  const onResize = () => {
    setWidth(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return (() => {
      window.removeEventListener('resize', onResize)
    })
  })

  const handleFeedbackClick = (event: any) => {
    event.preventDefault()
    feedback.current?.show()
  }

  const generateTabs = () => {
    if (roles) {
      if (roles.includes(Constants.AdminRole)) {
        return <HeaderNav drawer label="Main">
          <NavLink className={`siteItem ${path === '/' && 'active'}`} to='/risk-workorders'>Risk Workorders</NavLink>
          <NavLink className={`siteItem ${path === '/'}`} to='/incident-management'>Incident Management</NavLink>
          <ListGroupDropdown className='siteItem' >
          Contacts
            <ListGroup>
              <ListGroupItem element='a' href='/ContractorsContacts'>
              Contractors
              </ListGroupItem>
              <ListGroupItem element='a' href='/SiteCareManagersContacts'>
              Site Care Managers
              </ListGroupItem>
              <ListGroupItem element='a' href='/WeekendCallContacts'>
              Weekend/On-Call
              </ListGroupItem>
            </ListGroup>
          </ListGroupDropdown>
          <NavLink className={`siteItem ${path === '/'}`} to='/links'>Links</NavLink>
          <div className='siteItem'>
            <a href="" style={{textDecoration: 'none'}} onClick={handleFeedbackClick}>Feedback</a>
          </div>
          {width < 721 ?
            <div className='siteItem'>
              <SignOutButton />
            </div> : <></>
          }
        </HeaderNav>
      }
    }
    else {
      return <HeaderNav drawer label="Main"></HeaderNav>
    }
  }

  return (
    <>
      <Feedback
        ref={feedback}
        headerText={'How was FM Helpdesk?'}
        bodyText={'We want to know how you found using FM Helpdesk today, so we can learn from your feedback and improve the tools you use.'}
      />
      <Header>
        <HeaderGlobalBar className='sainsbury'>
          <HeaderGlobalItem className='sainsburysItem'>
            <Sainsburys className='sainsburysLogo' aria-label="Sainsbury's logo" />
          </HeaderGlobalItem>
          <HeaderGlobalItem align='right'>
            <SignOutButton />
          </HeaderGlobalItem>
        </HeaderGlobalBar>
        <HeaderMainBar className='headerMainBar'>
          <HeaderLogo className='headerLogo'>
            FM Helpdesk
          </HeaderLogo>
          {generateTabs()}
        </HeaderMainBar>
      </Header>
    </>
  )
}

export default PageHeader