import React, { useState, useEffect } from 'react';


const Timer = ( value : number) => {
  const totalSeconds = value;

  const hours = totalSeconds < 0 ? Math.ceil(totalSeconds / 3600) : Math.floor(totalSeconds / 3600)
  const [timerCheck , setTimerCheck ] = useState(false)
  const [countHours, setCountHours] = useState(hours);
  const [countMinutes, setCountMinutes] = useState(totalSeconds < 0 ? Math.ceil((totalSeconds % 3600) / 60) : Math.floor((totalSeconds % 3600) / 60))
  const [countSeconds, setCountSeconds] = useState((totalSeconds < 0 && totalSeconds % 60 === 0) ? -1 : totalSeconds % 60);


  useEffect(() => {
    const interval = setInterval(() => {
      setCountSeconds((prevSeconds) => prevSeconds - 1);

      if(countMinutes === 0 && countSeconds === 0 && countHours=== 0){
        setCountSeconds(-1)
        setTimerCheck(true)
      }
      if (countSeconds === -60 ) {
        setCountMinutes((prevMinutes) => prevMinutes - 1);
        if (countMinutes === -60) {
          setCountHours((prevHours) => prevHours - 1);
          setCountMinutes(-1);
        }
        setCountSeconds(-1);
      }
      if (countSeconds === 0 && !(countMinutes === 0 && countHours === 0) ) {
        setCountMinutes((prevMinutes) => prevMinutes - 1);
        if (countMinutes === 0) {
          setCountHours((prevHours) => prevHours - 1);
          setCountMinutes(59);
        }
        setCountSeconds(59);
      }
    }, 1000);

    return () => clearInterval(interval);

  }, [countSeconds, countMinutes, countHours]);

  const formattedTime = `${( totalSeconds < 0 || countSeconds < 0 ) ? '-' : ''}${Math.abs(countHours)
    .toString().padStart(2, '0')}:${Math.abs(countMinutes)
    .toString().padStart(2, '0')}:${Math.abs(countSeconds)
    .toString().padStart(2, '0')}`;

  return (
    <div>
      { ( totalSeconds < 0 || countSeconds < 0 ) ?
      <div style={{color:"red"}}>{formattedTime}</div>
      : <div style={{color:"#f06c00"}}>{formattedTime}</div>
      }
    </div>)
};

export default Timer;