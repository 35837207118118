import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { InteractionType } from '@azure/msal-browser'
import { useMsalAuthentication, AuthenticatedTemplate, useMsal } from '@azure/msal-react'

import './App.css'
import './sass/index.scss'
import { AppContext } from './context/AppContext'
import Constants from './utils/constants'
import HomePage from './components/HomePage'
import Unauthorised from './components/Unauthorised'
import RiskWorkorders from './components/RiskWorkorders'
import WorkOrderDetails from './components/workOrderDetails'
import IncidentManagement from './components/IncidentManagement'
import Links from './components/ApplicationLinks'
import IncidentWorkordersDetails from './components/IncidentWorkordersDetails'
import ContractorsContacts from './components/ContractorsContacts'
import SiteCareManagersContacts from './components/SiteCareManagersContacts'
import WeekendCallContacts from './components/WeekendCallContacts'

interface Claims {
  roles: string[]
}

const App = () => {
  const msal = useMsal()
  const context = useContext(AppContext)
  const [claims, setClaims] = useState({ roles: [] } as Claims)
  const request = useMemo(() => ({ scopes: [context?.apiScope ?? ''] }), [context]) 

  const { error, login, result } = useMsalAuthentication(InteractionType.Silent, request)


  useEffect(() => {
    
    if (error) {
      login(InteractionType.Redirect, request)
    }
  }, [error, login, request])

  useEffect(() => {
    if (result && result.idTokenClaims) {
      setClaims(result.idTokenClaims as Claims)
    }
    else if (result === null && msal.accounts.length > 0) {
      setClaims(msal.accounts[0].idTokenClaims as Claims)
    }
  }, [result, msal.accounts])

  const generateRouteChildren = (roles: string[]) => {
    if ((roles && roles.length > 0) && (roles.includes(Constants.AdminRole))) {
        return [
          { path: '*', element: <Navigate to='/' /> },
          { path: '/', element: <RiskWorkorders status={true}/> },
          { path: 'links',element:<Links/>},
          { path: 'risk-workorders', element: <RiskWorkorders status={true}/> },
          { path: 'incident-management', element: <IncidentManagement status={true}/> },
          { path: 'risk-workordersfilter', element: <RiskWorkorders status={false}/> },
          { path: 'incident-managementfilter', element: <IncidentManagement status={false}/> },
          { path: 'risk-workorders/:workordernumber', element: <WorkOrderDetails /> },
          { path: 'incident-management/:workordernumber', element: <IncidentWorkordersDetails /> },
          {path: 'ContractorsContacts', element: <ContractorsContacts />},
          {path: 'SiteCareManagersContacts',element:<SiteCareManagersContacts/>},
          {path: 'WeekendCallContacts',element:<WeekendCallContacts/>}
        ]
    }
    else {
      return [
        { path: '*', element: <Navigate to='/' /> },
        { path: '/', element: <Unauthorised /> }
      ]
    }
  }

  const routes = {
    path: '/',
    element: <HomePage roles={claims.roles} />,
    children: generateRouteChildren(claims.roles ?? null),
  }

  const routing = useRoutes([routes])

  return (
    <AuthenticatedTemplate>{routing}</AuthenticatedTemplate>
  )
}

export default App