import ApiClient from './ApiClient'
import {ContractorContactDetailsModel, DDLBinding, ContractDetailsByCName,DetailsByContractName, CompanyContactsFilter} from '../types/Entities/ContractorContacts'


const getcompanycollection = async (apiClient: ApiClient) => {
  return apiClient
    .get<Array<DDLBinding>>(`ContractorContactsDetail/getcompanycollection`)
    .catch(error => { throw error })
}

const getcontractdetailsbycompany = async (apiClient: ApiClient, cname: string):
  Promise<ContractDetailsByCName> => {
  return apiClient
    .get<ContractDetailsByCName>(`ContractorContactsDetail/getcontractdetailsbycompany?cname=${cname}`)
    .catch(error => { throw error })
}



const getdetailsbycompany = async (apiClient: ApiClient, contractname: string):
  Promise<DetailsByContractName> => {
  return apiClient
    .get<DetailsByContractName>(`ContractorContactsDetail/getdetailsbycontract?contractname=${contractname}`)
    .catch(error => { throw error })
}

const getcontractorcontactdetails = async (apiClient: ApiClient, payload: CompanyContactsFilter): Promise<ContractorContactDetailsModel> => {
  const queryParams = new URLSearchParams(payload as any).toString();
  return apiClient
    .get<ContractorContactDetailsModel >(`ContractorContactsDetail/getcontractorcontactdetails?${queryParams}`)
    .catch(error => { throw error });
}

const ContractorContactsApi = {
  getcontractorcontactdetails,
  getcontractdetailsbycompany,
  getcompanycollection,
  getdetailsbycompany
}
export default ContractorContactsApi