import React from 'react'
import { Link } from "react-router-dom";
import { Card, GridWrapper, GridItem } from "@jsluna/react";
import moment from "moment";
import {Workorders} from "../../../types/Entities/Workorders"
import { IncidentManagements } from "../../../types/Entities/IncidentManagement"
import Timer from '../Timer';


const IncidentList = (props: { workorder: IncidentManagements}) => {
  const pathName=window.location.pathname.split("/")[1] == 'incident-management'
  return (
    <Link
    to={{ pathname: `/incident-management/${props.workorder.workOrderNumber}`}}
  >
    <Card
      padded="true"
      className="cb-c-alert-list-item__drop-shadow cb-c-alert-list-item"
    >
      <GridWrapper className="ln-o-grid--center">
        <GridItem size={{ xs: "1/1", sm: "1/6", md: "1.5/10" }}>
          <div className="ln-u-display-3-fixed cb-c-alert-list-item__value cb-u-text-align-center-responsive">
            {
                Timer(Math.round(props.workorder.timer)) 
            }
          </div>
        </GridItem>
        <GridItem size={{ xs: "1/1", sm: "5/6", md: "8.5/10" }}>
          <GridWrapper>
            <GridItem size={{ xs: "1/1", sm: "1/2", md: "7/10" }}>
              <div className="ln-u-font-weight-bold cb-c-alert-list-item__description">
                <h4>{props.workorder.workOrderNumber}</h4>
              </div>
              <div className="cb-c-alert-list-item__alert-reference">
                {/* {props.workorder.assetNumber} | {props.workorder.siteName} | {props.workorder.siteDesc} */}
                  {`${props.workorder.siteName}  ${props.workorder.siteDesc} | ${props.workorder.region} | ${props.workorder.zone}`}
              </div>
            </GridItem>
            <GridItem size={{ xs: "1/1", sm: "1/2", md: "3/10" }}>
              <div className="cb-u-text-align-right-responsive">
                <div className="ln-u-font-weight-bold">
                  {props.workorder.status}
                </div>
              </div>
            </GridItem>
            <GridItem
              size={{ xs: "1/1", sm: "1/1", md: "1/1" }}
              className="cb-c-alert-list-item__sensor-detail"
            >

            {`${props.workorder.clientName} | ${props.workorder.problemType}`}
                              {props.workorder.tradeLossScriptResponse.length > 0 ? ` | ${props.workorder.tradeLossScriptResponse}` : ``}

              {/* {props.workorder.providerName} | {props.workorder.category} | {props.workorder.subCategory} | ({props.workorder.woStatus}) */}
            </GridItem>
            <GridItem size={{ xs: "1/1", sm: "1/2", md: "7/10" }}>
              {`Opened : ${moment(props.workorder.dateCreated).format('DD MMM YY, HH:mm')}`}
            </GridItem>
          </GridWrapper>
        </GridItem>
      </GridWrapper>
    </Card>
  </Link>
  )
}

export default IncidentList