import ApiClient from './ApiClient'
import {WeekendOnCallModel,WeekendFilter} from '../types/Entities/WeekendOnCallType'


const getweekendoncalldetailsbydate = 
async (apiClient: ApiClient,payload: WeekendFilter) => {
  const queryParams = new URLSearchParams(payload as any).toString();
  return apiClient
    .get<WeekendOnCallModel>(`WeekendOnCall/getweekendoncalldetailsbydate?${queryParams}`)
    .catch(error => { throw error })
}

const WeekendOnCallApi = {
  getweekendoncalldetailsbydate
}
export default WeekendOnCallApi
