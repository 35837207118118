import { ConfigurationType } from '../types/ConfigurationType'

const config: ConfigurationType = {
    scope: process.env.REACT_APP_MSAL_CLIENT_SCOPE || '',
    redirectUrl: process.env.NODE_ENV === 'production' ? window.location.origin : 'http://localhost:3000',
    clientAuthority: process.env.REACT_APP_MSAL_AUTHORITY || '',
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID || '',
    apiBaseUrl: process.env.NODE_ENV === 'production' ? window.location.origin.replace('wa-fmreporting-prod-helpdesk.azurewebsites.net', 'wa-fmreporting-prod-api.azurewebsites.net').replace('wa-fmreporting-test-helpdesk.azurewebsites.net', 'wa-fmreporting-test-api.azurewebsites.net').replace('fmhelpdesk.sainsburys.co.uk', 'wa-fmreporting-prod-api.azurewebsites.net') + '/api/' : 'http://localhost:5109/api/'
}

export { config }