import {IncidentManagements} from '../../types/Entities/IncidentManagement'
import moment from "moment";
import Timer from './Timer';
import React, { useState } from 'react';
import { TextArea, TextAreaField } from "@jsluna/form";

import { Modal, ModalHeading } from "@jsluna/modal";
import { GridWrapper, GridItem } from "@jsluna/grid";
import { CancelButton, TextButton, FilledButton } from "@jsluna/button";
// import { hover } from '';


const IncideDetailsTable = ( props : { table : IncidentManagements}) =>{

    console.log(props);
    // const [ updateRequired , setUpdateRequired ] = useState("")
    const updateRequired = props.table.updateFrequency < 1 ? `${props.table.updateFrequency * 60} minutes` : `${props.table.updateFrequency} ${props.table.updateFrequency > 1 ? 'hours' : 'hour'}`
    const [isOpen , setIsOpen] = useState(false)

    console.log (props.table)

    return(
        <>
            {
  <Modal small={true} alert hideCloseButton open={isOpen} headingId="dialog-modal" style={{display: 'flow',alignContent: "center",textAlign: '-webkit-center'}}>
  <div>
      <GridItem size="1" className="ln-u-hard" >
        <div>
          <b>Name: {props.table.name ? props.table.name : ' '}</b><br/>
          
          <b>Phone: {props.table.phone ? props.table.phone : ' '}</b><br/>
          <b>Email: {props.table.email ? props.table.email : ' '}</b><br/>
        </div>
      </GridItem>
     <FilledButton style={{float:'right'}}      
      onClick={() => setIsOpen(false)}>
      Close
    </FilledButton>
  </div>
  </Modal>
}
        <table className="workorderdetails">
        <tbody style={{ border: "1 px black" }}>
            <tr>
            <th>Work Order</th>
            <td>{props.table.workOrderNumber}</td>
            <th>Date Created</th>
            <td>
            {props.table.dateCreated ?
                moment(props.table.dateCreated).format(
                    "DD MMM YY, HH:mm"
                )
                : ""}
                {}
            </td>
            </tr>
            <tr>
            <th>Site</th>
            <td>{props.table.siteName} {props.table.siteDesc}</td>
            <th>Date Last Modified</th>
            <td>
            {props.table.dateModified ?
                moment(props.table.dateModified).format(
                    "DD MMM YY, HH:mm"
                )
                : ""}
            </td>
            </tr>
            <tr>
            <th>Site Type</th>
                <td>{props.table.siteType} </td>
            <th>Date Completed</th>
            <td>
                {props.table.dateServiceCompleted}
            </td>
            </tr>
            <tr>
            <th className='linkbutton' onClick={() => setIsOpen(true)}>Region</th>
                <td className='linkbutton'  onClick={() => setIsOpen(true)}>{props.table.region} | <span  > {props.table.name}</span></td>
            <th>Incident Timer</th>
                {/* <td>{Timer(props.table.timer)}</td> */}
                <td>{props.table.incidentTimer}</td>
            </tr>
            <tr>
            <th>Location at Site</th>
            <td>{props.table.location}</td>
            <th>Updates Required</th>
            <td>{updateRequired}</td>
            </tr>
            <tr>
            <th>Incident Category</th>
            <td>{props.table.subCategory}</td>
            <th>Incident Status</th>
            <td>{props.table.status}</td>
            </tr>
            <tr>
            <th>Incident type</th>
            <td>{props.table.problemType}</td>
            <th>Client Name</th>
            <td>{props.table.clientName}</td>
            </tr>
            <tr>
            <th rowSpan={2} colSpan={1}>
                Incident Description
            </th>
            <td colSpan={3}>
                <TextArea
                rows={5}
                name="textarea-1"
                value={props.table.problemDescription}
                disabled
                style={{
                    opacity: "1",
                    background: "#f0f0f0",
                    border: "none",
                    paddingLeft:'0px'
                }}
                />
            </td>
            </tr>
        </tbody>
        </table>
        </>
    )
}

export default IncideDetailsTable