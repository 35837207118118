import React from 'react'
import { Link } from "react-router-dom";
import { Card, GridWrapper, GridItem } from "@jsluna/react";
import moment from "moment";
import {Workorders} from "../../../types/Entities/Workorders"
import { IncidentManagements } from "../../../types/Entities/IncidentManagement"
import Timer from '../Timer';


const WorkordersList = (props: { workorder: Workorders}) => {
  return (
    <Link
    to={{ pathname: `/risk-workorders/${props.workorder.workOrderNumber}`}}
  >
    <Card
      padded="true"
      className="cb-c-alert-list-item__drop-shadow cb-c-alert-list-item"
    >
      <GridWrapper className="ln-o-grid--center">
        <GridItem size={{ xs: "1/1", sm: "1/6", md: "1.5/10" }}>
          <div className="ln-u-display-3-fixed cb-c-alert-list-item__value cb-u-text-align-center-responsive">
             {Math.round(props.workorder.risK_SCORE)}
          </div>
        </GridItem>
        <GridItem size={{ xs: "1/1", sm: "5/6", md: "8.5/10" }}>
          <GridWrapper>
            <GridItem size={{ xs: "1/1", sm: "1/2", md: "7/10" }}>
              <div className="ln-u-font-weight-bold cb-c-alert-list-item__description">
                <h4>{props.workorder.workOrderNumber}</h4>
              </div>
              <div className="cb-c-alert-list-item__alert-reference">
                {props.workorder.assetNumber} | {props.workorder.siteName} | {props.workorder.siteDesc}
              </div>
            </GridItem>
            <GridItem size={{ xs: "1/1", sm: "1/2", md: "3/10" }}>
              <div className="cb-u-text-align-right-responsive">
                <div className="ln-u-font-weight-bold">
                  {props.workorder.fM_WOStatus}
                </div>
              </div>
            </GridItem>
            <GridItem
              size={{ xs: "1/1", sm: "1/1", md: "1/1" }}
              className="cb-c-alert-list-item__sensor-detail"
            >
              {props.workorder.providerName} | {props.workorder.category} | {props.workorder.subCategory} | ({props.workorder.woStatus})
            </GridItem>
            <GridItem size={{ xs: "1/1", sm: "1/2", md: "7/10" }}>
              {props.workorder.problemType}
            </GridItem>
            <GridItem size={{ xs: "1/1", sm: "1/2", md: "3/10" }}>
              <div className="cb-u-text-align-right-responsive">
                {props.workorder.dateModified ? moment(props.workorder.dateModified).format('DD MMM YY, HH:MM') : ''}
              </div>
            </GridItem>
          </GridWrapper>
        </GridItem>
      </GridWrapper>
    </Card>
  </Link>
  )
}

export default WorkordersList