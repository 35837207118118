import ApiClient from './ApiClient'
import { links } from '../types/Entities/Links'


const getallApplicationLinks = async (apiClient: ApiClient) => {
  return apiClient
    .get<Array<links>>(`ApplicationUrl/getallapplicationurl`)
    .catch(error => { throw error })
}



const ApplicationLinksApi = {
  getallApplicationLinks
}


export default ApplicationLinksApi