import { Search } from '@jsluna/icons'
import ContractorContactsApi from '../../api/contractorContactsApi'
import FormAlerts from "../Common/FormAlerts";
import { useApiClient } from '../../context/AppContext'
import { Pagination } from '@jsluna/pagination';
import { Modal, ModalHeading } from "@jsluna/modal"
import React, { useRef, useState, useEffect } from 'react'
import { FilledButton, OutlinedButton, TextInputField, TextInput } from '@jsluna/react'
import { ContractorContactDetailsModel,DDLBinding,CompanyContactsFilter  } from '../../types/Entities/ContractorContacts'
import { GridWrapper, GridItem, SearchInput, CheckboxField, FormGroup,  SelectField } from '@jsluna/react'


const ContractorsContacts = () => {
  const [indexOneName , setIndexOneName] = useState('')
  const [currentPage, setCurrentPage] = React.useState(1)
  const [nameSelector , setNameSelector]  = useState('')
  const [contractorSelector , setContractorSeletor] = useState('')
  const [detailsSelector , setDetailsSeletor] = useState('')
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [errorAlert, setErrorAlert] = useState<false | string>(false);
  const [successAlert, setSuccessAlert] = useState<false | string>(false);
  const [loader, setLoader] = useState(true)
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [names, setNames] = useState<DDLBinding[]>([])
  const [contractor, setContractor] = useState<DDLBinding[]>([]);
  const [detail, setDetails] = useState<DDLBinding[]>([]);
  const [contractorContactDetailsModel, setContractorContactDetailsModel] = useState({} as ContractorContactDetailsModel);  
  
  const [contractorFilter, setcontractorFilter] = useState({} as CompanyContactsFilter);
  const apiClient = useApiClient();  
  const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)

  useEffect(() => {
    setLoader(true)
    loadCompanyDropDownValues();
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  },[]);

  const loadCompanyDropDownValues = async () => {
    return ContractorContactsApi
    .getcompanycollection(apiClient)
    .then((response) => {
      var nameSelectorValue = response ? response[0].label : ""
      setIndexOneName(nameSelectorValue)
      const contractorsdts = {
        CompanyName: nameSelectorValue,
        CompanyHeaderName: '',
        DetailName: '',
        StartIndex: 1,
        PageSize: 20
        }
      setcontractorFilter(contractorsdts)
      loadcontractorcontactsdts(contractorsdts)
        // setcontractorFilter(prevFilter => ({
        //   ...prevFilter,
        //   CompanyName: nameSelectorValue
        // }));
      setNames(response)
      setNameSelector(nameSelectorValue)
      loadHeaderDropDownValues(nameSelectorValue)
    })
      .catch(error => setErrorAlert('Something went wrong!'))
  }

  const loadHeaderDropDownValues = async (cname: string) => {
    return ContractorContactsApi
    .getcontractdetailsbycompany(apiClient,cname)
    .then((response) => {
      setContractor(response.ddlContract)
      //setDetails(response.ddlDetail)
    })
      .catch(error => setErrorAlert('Something went wrong!'))
  }

  const loadDetailsDropDownValues = async (contractname: string) => {
    return ContractorContactsApi
    .getdetailsbycompany(apiClient,contractname)
    .then((response) => {
      setDetails(response.ddlDetail)
    })
      .catch(error => setErrorAlert('Something went wrong!'))
  }
  

  const loadcontractorcontactsdts = async (filters: CompanyContactsFilter): Promise<ContractorContactDetailsModel> => {
    return ContractorContactsApi
      .getcontractorcontactdetails(apiClient,filters)
      .then((response) => {
        setContractorContactDetailsModel(response); 
      })
      .catch(error => {
        console.log(error)
        setErrorAlert('Something went wrong!')
        scrollToTop()
        return error; 
      })
    }
  
    // const filterchangeevent = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //   const element = event.target as HTMLSelectElement;
    //   console.log(element , element.value)
    //   if(element.id == "nameSelector"){
    //     setcontractorFilter(prevFilter => ({
    //       ...prevFilter,
    //       CompanyName: element.value,
    //       CompanyHeaderName: '',
    //       DetailName:''   
    //     }));
    //     setNameSelector(element.value)
    //     setContractorSeletor('')
    //     setDetailsSeletor('')
    //     if(element.value)
    //       loadHeaderDropDownValues(element.value);
       
    //   }
    //   else if(element.id == "contractorSelector"){
    //     debugger;
    //     setcontractorFilter(prevFilter => ({
    //       ...prevFilter,
    //       CompanyHeaderName: element.value  
    //     }));
    //     setContractorSeletor(element.value);
    //     if(element.value)
    //     loadDetailsDropDownValues(element.value);
        
    //   }
    //   else if(element.id == "detailSelector"){
    //     setcontractorFilter(prevFilter => ({
    //       ...prevFilter,
    //       DetailName: element.value  
    //     }));
    //     setDetailsSeletor(element.value)
    //   }   
      
    //   setcontractorFilter(prevFilter => ({
    //     ...prevFilter,
    //     StartIndex: 1
    //   }));

    //   setcontractorFilter(prevFilter => ({
    //     ...prevFilter,
    //     PageSize: 20 
    //   }));
  
    // }

    const filterchangeevent = (event: React.ChangeEvent<HTMLSelectElement>) => {
      debugger
     const element = event.target as HTMLSelectElement;
     console.log(element, element.value);
    
     if (element.id === "nameSelector") {
       if (element.value !== '') {
         // When a contractor name is selected, update the contractor filter.
         setcontractorFilter(prevFilter => ({
           ...prevFilter,
           CompanyName: element.value,
           CompanyHeaderName: '',
           DetailName: '',
           
         }));
         setNameSelector(element.value);
         setContractorSeletor('');
         setDetailsSeletor('');
         loadHeaderDropDownValues(element.value);
         setDetails([]);
       } else {
         // If no contractor name is selected, reset the filter to blank.
         setcontractorFilter({
           CompanyName: '',
           CompanyHeaderName: '',
           DetailName: '',
           StartIndex: 1,
           PageSize: 20
         });
        
        setContractor([]);
        
        setDetails([]);
         setNameSelector('');
         setContractorSeletor('');
         setDetailsSeletor('');
       }
     } else if (element.id === "contractorSelector") {
       debugger;
       if (element.value !== '') {
         setcontractorFilter(prevFilter => ({
           ...prevFilter,
           CompanyHeaderName: element.value,
           DetailName: ''
         }));
         setContractorSeletor(element.value);
         setDetailsSeletor('');
         loadDetailsDropDownValues(element.value);
       } else {
         setDetails([]);
         setContractorSeletor('');
         setDetailsSeletor('');
         setcontractorFilter(prevFilter => ({
           ...prevFilter,
           CompanyHeaderName: '',
           DetailName: ''
         }));
         
         // Reload the header data based on the current name selector value.
         if (nameSelector) {
           loadHeaderDropDownValues(nameSelector);
         }

       }
     } else if (element.id === "detailSelector") {
       setcontractorFilter(prevFilter => ({
         ...prevFilter,
         DetailName: element.value
       }));
       setDetailsSeletor(element.value);
     }
    
     // Ensure pagination settings are always updated.
     setcontractorFilter(prevFilter => ({
       ...prevFilter,
       StartIndex: 1,
       PageSize: 20
     }));
   };

    const serachFilter= async () => {
      setLoader(true)
      loadcontractorcontactsdts(contractorFilter);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }

    

    const discardFilter = () => {
      
      const contractnameDF=names[0].label;

      setNameSelector(indexOneName)

      setContractorSeletor('')
      setDetailsSeletor("")
      const contractor = {
        CompanyName: contractnameDF,
        CompanyHeaderName: '',
        DetailName: '',
        StartIndex: 1,
        PageSize: 20
        }
      setDetails([]);
      loadHeaderDropDownValues(contractnameDF);
      setcontractorFilter(contractor)
      // setContractorContactDetailsModel({} as ContractorContactDetailsModel)
      loadcontractorcontactsdts(contractor);
      
    }

  const clearAlerts = () => {
        setErrorAlert(false);
        setSuccessAlert(false);
    };

    return (
      <>
      <FormAlerts
        showError={errorAlert}
        showSuccess={successAlert}
        onClose={clearAlerts}
      />
      <GridWrapper>
        <GridItem size={{ xs: "1/1", lg: "1/4" }} >
          <div
            className={ "cb-c-alerts-page__filter-container--full-screen"}>
            <GridItem
              size={{ xs: "1/1", sm: "1/1" }}
              className="cb-c-alerts-page__left-panel alerts-page__filter">
              <div className="cb-c-alerts-page__options">
                <div>
                  <div>
                    <GridWrapper>
                      <GridItem size="1/1">
                        <h3>Filter Contacts</h3>
                      </GridItem>
                    </GridWrapper>
                  </div>
                  <FormGroup>
                    <GridWrapper>
                      <GridItem>
                        <SelectField
                          name="nameSelector"
                          label="Names"
                          value={nameSelector}
                          placeholder={"Please Select"}
                          noResultsMessage="No data found"
                          // options={names}
                          options={names.map((item) => ({
                            label: item.label,
                            value: item.label
                          }))}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => filterchangeevent(e)}
                        />
                      </GridItem>
                      <GridItem>
                        <SelectField
                          name="contractorSelector"
                          label="Service"
                          value={contractorSelector}
                          placeholder={"Please Select"}
                          noResultsMessage="No data found"
                          options={contractor.map((item) => ({
                            label: item.label,
                            value: item.label
                          }))}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => filterchangeevent(e)}
                        />
                      </GridItem>
                      <GridItem>
                        <SelectField
                          name="detailSelector"
                          label="Detail"
                          value={detailsSelector}
                          placeholder={"Please Select"}
                          noResultsMessage="No data found"
                          options={detail.map((item) => ({
                            label: item.label,
                            value: item.label
                          }))}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => filterchangeevent(e)}
                        />
                      </GridItem>
                    </GridWrapper>
                  </FormGroup>
                  <>
                    <GridItem size={{ xl: '1', ss: '1', default: '1/1' }} className='formInputMaxWidth'>
                      <div className='formButtonGroup'>
                        <OutlinedButton size='200px' className='formDiscardButton' onClick={discardFilter} >Discard</OutlinedButton>
                        <FilledButton size='200px' onScroll={scrollToTop} onClick={serachFilter} className='formSaveButton' >Search</FilledButton>
                      </div>
                    </GridItem>
                  </>

                </div>
              </div>
            </GridItem>
          </div>
        </GridItem>
        {/* {contractorContactDetailsModel.contactDetails && contractorContactDetailsModel.contactDetails.length > 0 && */}
        <GridItem
          className={"cb-c-alerts-page__right-panel"}
          size={{ xs: "1/1", lg: "3/4" }}>
            
            <h4 style={{ margin: "10px"}}>
              {contractorContactDetailsModel.name} 
              {contractorContactDetailsModel.contract && contractorContactDetailsModel.contract.length > 0 ?  ` | ${contractorContactDetailsModel.contract}` : ``}
              {contractorContactDetailsModel.detail && contractorContactDetailsModel.detail.length > 0 ? ` | ${contractorContactDetailsModel.detail}` : ``}
              
            </h4>

            <div style={{ margin: "10px"}}>
            Office hours weekdays : {contractorContactDetailsModel.officeHoursweekdays}<br/>
            Office hours weekends : {contractorContactDetailsModel.officeHoursweekends}
            </div>
            
            <table style={{ margin: "10px"}} className="linkedworkorder" >
            <tbody >
              <tr>
                <th></th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Note</th>
              </tr>
              {
                (loader === true) && (<div className='loader'></div>)
              }
              {
                contractorContactDetailsModel.contactDetails && contractorContactDetailsModel.contactDetails.length > 0 &&
                contractorContactDetailsModel.contactDetails.map((dts, index) => (
                 
                <tr key={index}>
                  <td>{dts.level}</td>

                  <td>{dts.name}</td>

                  <td>{dts.email}</td>

                  <td>{dts.phone}</td>

                  <td>{dts.note}</td>

                </tr>
              ))}
            </tbody>
          </table>
                 <div style={{display: 'flex' , alignItems: 'center'}}>
                  <FilledButton onClick={() => setIsOpen(true)} style={{ margin: "10px"}}>Out of Hours Dispatches</FilledButton>
                  { contractorContactDetailsModel.recordsCount && contractorContactDetailsModel.recordsCount > 0 &&
                  <Pagination
                      showFirstAndLast
                      showPages
                      current={currentPage}
                      total={contractorContactDetailsModel.recordsCount}
                      onChange={(page : number, e: { preventDefault: () => void; }) => {
                        setCurrentPage(page)
                        const filterforPage={...contractorFilter}
                        filterforPage.StartIndex=page
                        localStorage.setItem('riskWorkorderFilter' , JSON.stringify(filterforPage))
                        setcontractorFilter(prevFilter => ({
                          ...prevFilter,
                          StartIndex: page
                        }));
                        loadcontractorcontactsdts(filterforPage);
                      }}
                    />}
                  </div>

        </GridItem>
        {/* } */}
      </GridWrapper>

{
  <Modal small={true} alert hideCloseButton open={isOpen} headingId="dialog-modal" style={{display: 'flow',alignContent: "center",textAlign: '-webkit-center'}}>
  <div>
      <GridItem size="1" className="ln-u-hard" >
        <div>
          <b>OOH Dispatch Number: {contractorContactDetailsModel.ooH_Dispatch_Number}</b><br/>
          <b>OOH Dispatch Contact: {contractorContactDetailsModel.ooH_Dispatch_Contact}</b><br/>
          <b>Note: {contractorContactDetailsModel.note}</b><br/>
        </div>
      </GridItem>
     <FilledButton style={{float:'right'}}      
      onClick={() => setIsOpen(false)}>
      Close
    </FilledButton>
  </div>
  </Modal>
}
      
    </>
    );
};

export default ContractorsContacts;
