import Modal from '../Modal'
import { TextArea,TextAreaField } from "@jsluna/form";
import LinkedWorkorder from '../../IncidentWorkordersDetails';
import { useState } from 'react';

interface Props {
  showModal: 'save' | 'discard' | 'complete' | 'rootcause' |false
  onClose: () => void
  onSave: (value?: string) => void
  onDiscard: () => void
  onComplete:()=>void
  primaryButtonDisabled?: boolean;
  // latestComment?:string;
}

const ConfirmationModal = (props: Props) => {
  const [textAreaValue, setTextAreaValue] = useState('');

  var modalHeading = '', modalBody = '', modalPrimaryBtnTitle = '', modalPrimaryBtnClickHandler = () => { }
  
  if (props.showModal === 'save') {
    modalHeading = 'Save Changes'
    modalBody = 'Do you want to save the changes ?'
    modalPrimaryBtnTitle = 'Save'
    modalPrimaryBtnClickHandler = props.onSave
  }
  else if (props.showModal === 'discard') {
    modalHeading = 'Discard Changes'
    modalBody = 'Do you want to discard the changes ?'
    modalPrimaryBtnTitle = 'Discard'
    modalPrimaryBtnClickHandler = props.onDiscard
  }
  else if (props.showModal === 'complete') {
    modalHeading = 'Save Changes'
    modalBody = 'Are you sure the incident is completed?'
    modalPrimaryBtnTitle = 'Complete'
    modalPrimaryBtnClickHandler = props.onComplete
  }
  else if(props.showModal === 'rootcause'){
    modalHeading = 'Root Cause Analysis'
    modalBody = ''

    modalPrimaryBtnTitle = 'Save'
    modalPrimaryBtnClickHandler = () => props.onSave(textAreaValue);
  }
  // else if (props.showModal === 'latestComment') {
  //   modalHeading = 'Latest Comment';
  //   modalBody = ''
  //   modalPrimaryBtnTitle = 'Close';
  //   modalPrimaryBtnClickHandler = props.onClose;
  // }
  
  return (
    <Modal
      isOpen={props.showModal !== false}
      heading={modalHeading}
      body={modalBody}
      primaryButtonTitle={modalPrimaryBtnTitle}
      secondaryButtonTitle='Close'
      handlePrimaryButtonClick={modalPrimaryBtnClickHandler}
      handleSecondaryButtonClick={props.onClose}
      primaryButtonDisabled={props.showModal === 'rootcause' && !textAreaValue}
    >
      {props.showModal === 'rootcause' && (
          <TextArea
            name="textarea-1"
            value={textAreaValue}
            onChange={(e:any) => setTextAreaValue(e.target.value) }
             />
      )}
      {/* {props.showModal === 'latestComment' && (
        <TextAreaField name="latest-comment">
          <TextArea name="textarea-1" />
        </TextAreaField>
      )} */}
      

      </Modal>
  )
}

export default ConfirmationModal