import ApiClient from './ApiClient'
import { Workorders , RiskWorkorderFilter, RiskWorkorderModel, postComment } from '../types/Entities/Workorders'



const getriskworkordersbywo = async (apiClient: ApiClient, workordernumber: string):
  Promise<Workorders> => {
  return apiClient
    .get<Workorders>(`RiskWorkorders/getriskworkordersbywo?workordernumber=${workordernumber}`)
    .catch(error => { throw error })
}

const getallriskworkorders = async (apiClient: ApiClient  ):
  Promise<Array<Workorders>> => {
  return apiClient
    .get<Array<Workorders>>(`RiskWorkorders/getallriskworkorders?`)
    .catch(error => { throw error })
}

const getallriskworkorderspaging = async (apiClient: ApiClient, payload: RiskWorkorderFilter): Promise<RiskWorkorderModel> => {
  const queryParams = new URLSearchParams(payload as any).toString();
  return apiClient
    .get<RiskWorkorderModel >(`RiskWorkorders/getriskworkordersbypaging?${queryParams}`)
    .catch(error => { throw error });
}

const getUsername = (apiClient: ApiClient) =>{
  return apiClient.getUsername()
}

const updatefmworkorderstatus= async(apiClient:ApiClient,payload:Workorders):
  Promise<Response> =>{
    return apiClient
    .post('RiskWorkorders/updatefmworkorderstatus',JSON.stringify(payload))
    .catch(error=>{throw error})
}
const gethelpdeskcommentsbywo= async(apiClient:ApiClient,workordernumber:string):
Promise<Array<postComment>> => {
  return apiClient
  .get<Array<postComment>>(`FMHelpdeskComments/gethelpdeskcommentsbywo?workordernumber=${workordernumber}`)
  .catch(error => { throw error })
}

const addhelpdeskcomments= async(apiClient:ApiClient,payload:postComment):
  Promise<Response> =>{
    return apiClient
    .post('FMHelpdeskComments/addhelpdeskcomments',JSON.stringify(payload))
    .catch(error=>{throw error})
}


const riskWorkordersApi = { 
  getallriskworkorders,
  getriskworkordersbywo,
  getallriskworkorderspaging,
  updatefmworkorderstatus,
  addhelpdeskcomments,
  getUsername,
  gethelpdeskcommentsbywo
}

export default riskWorkordersApi